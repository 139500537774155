import React, { Component } from 'react';
import { Layout, Menu, Button, Avatar, Space, Row, Col } from 'antd';

//REDUX
import { connect } from 'react-redux';
import { getUser } from '../../actions/user';

import './Header.less';

const { SubMenu } = Menu;
const { Header: AntHeader } = Layout;

export class Header extends Component {
  componentDidMount() {
    this.props.getUser();
  }

  render() {
    return (
      <AntHeader className="header">
        <Row id="main_header_row">
          <Col id="logo_col">
            <a href="/">
              <img style={{ width: 46 }} src="/static/images/logo/logo.svg" />
            </a>
          </Col>

          <Col id="menu_col">
            <Menu id="main_navigation" mode="horizontal">
              <SubMenu key="SubMenu" title="Fyrirtæki">
                <Menu.ItemGroup title="Item 1">
                  {/* {companies.map((company) => { */}
                  <Menu.Item key="setting:1">Um okkur</Menu.Item>;{/* })} */}
                </Menu.ItemGroup>
              </SubMenu>
            </Menu>
          </Col>

          <Col id="user_col">
            <Menu className="main_menu" theme="dark" mode="horizontal">
              {window.me && (
                <Menu.SubMenu
                  style={{ marginLeft: 'auto' }}
                  icon={
                    <Avatar
                      src={this.props.user?.image?.image}
                      style={{
                        margin: '16px',
                        backgroundColor: '#1890ff',
                      }}
                    >
                      {(this.props.user?.signature_name || '')
                        .charAt(0)
                        .toUpperCase()}
                    </Avatar>
                  }
                >
                  <Menu.Item>
                    <a href="/profill/">Prófíllinn minn</a>
                  </Menu.Item>
                  <Menu.Item>
                    <a href="/utskra/">Útskrá</a>
                  </Menu.Item>
                </Menu.SubMenu>
              )}

              {!window.me && (
                <Menu.Item
                  style={{
                    marginLeft: 'auto',
                    backgroundColor: 'transparent',
                    cursor: 'auto',
                  }}
                >
                  <Space>
                    <Button className="btn-success" type="default">
                      <a
                        style={{
                          color: '#fff',
                        }}
                        href="/nyskra/"
                      >
                        Nýskráning
                      </a>
                    </Button>

                    <Button type="dashed" style={{ color: '#fff' }}>
                      <a href="/innskra/">Innskrá</a>
                    </Button>
                  </Space>
                </Menu.Item>
              )}
            </Menu>
          </Col>
        </Row>
      </AntHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, { getUser })(Header);
