import React, { useState, useEffect } from 'react';
import { kr, getUrlParameter } from '../../utils/utils';
import { Table } from 'antd';
import moment from 'moment';

// Less
import './Product.less';

const website_id = getUrlParameter('website_id');
const product_id = getUrlParameter('product_id');

const get_website = async (id) => {
  const res = await fetch(`/api/website/${website_id}`);
  const data = await res.json();
  return data;
};

const get_snapshots = async () => {
  const res = await fetch(
    `/api/product_snapshot/?product__product_id=${product_id}&website=${website_id}&ordering=-created`
  );
  const data = await res.json();
  return data;
};

export default function Shop() {
  const [website, setWebsite] = useState({});
  const [snapshots, setSnapshots] = useState([]);
  const [snapshotCount, setSnapshotCount] = useState(0);

  useEffect(() => {
    get_website().then((website) => setWebsite(website));
    get_snapshots().then((res) => {
      setSnapshots(res.results);
      setSnapshotCount(res.count);
    });
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>{snapshots.length && snapshots[0].product.title}</h1>
      <div>Fjöldi skyndimynda: {snapshotCount}</div>
      <hr></hr>
      <div>
        <Table
          pagination={{ pageSize: 200 }}
          columns={[
            { title: 'Dagsetning', dataIndex: 'date', key: 'date' },
            { title: 'Lagerstaða', dataIndex: 'quantity', key: 'quantity' },
            {
              title: 'Fjöldi selt síðan síðast',
              dataIndex: 'sold_since_last',
              key: 'sold_since_last',
            },
            {
              title: 'Innkoma síðan síðast',
              dataIndex: 'income_since_last',
              key: 'income_since_last',
            },
            { title: 'Verð', dataIndex: 'price', key: 'price' },
            { title: 'Afsláttur', dataIndex: 'discount', key: 'discount' },
          ]}
          dataSource={snapshots.map((snapshot, key) => {
            return {
              key: key,
              date: moment(snapshot.created).format('LL'),
              quantity: snapshot.quantity,
              sold_since_last: snapshot.sold_since_last,
              income_since_last: kr(snapshot.income_since_last),
              price: kr(snapshot.price),
              discount: snapshot.discount,
            };
          })}
        ></Table>
        {/* {snapshots.map((snapshot, key) => {
          return (
            <div className="product_detail" key={key}>
              <div
                className="product_image"
                style={{
                  backgroundImage: `url(${snapshot.product.main_img_url})`,
                }}
              ></div>
              <div>
                <h5>{snapshot.product.title}</h5>
              </div>
              <div>
                <h2>
                  <strong>{snapshot.sold_since_last}</strong>
                </h2>
              </div>
            </div>
          );
        })} */}
      </div>
    </div>
  );
}
