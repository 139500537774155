import React, { Component } from 'react';
import { Layout, Card, Space } from 'antd';

// Components
import HeroSection from '../../components/HeroSection/HeroSection';

// Libraries

// Less
import './Frontpage.less';

//REDUX
import { connect } from 'react-redux';
import { getUser } from '../../actions/user';
import Axios from 'axios';

const { Content } = Layout;

export const kr = (x) => {
  const round = Math.round(x);
  let parts = round.toString().split('.');
  parts[0] = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  return parts.join('.') + ' kr';
};

export class Frontpage extends Component {
  state = {
    loading: true,
    websites: [],
  };

  constructor() {
    super();
    Axios.get('/api/website/').then((rsp) => {
      rsp.data.results.forEach((website) => {
        Axios.get('/api/product/?page_size=1&website=' + website.id).then(
          (r) => {
            let w = website;
            w.product_count = r.data.count;
            w.products = r.data.results;
            this.setState({ websites: [...this.state.websites, website] });
          }
        );
      });
    });
  }

  render() {
    return (
      <>
        <Content>
          <HeroSection
            id="frontpage_hero_section"
            vheight="100"
            heightSubtract="64px"
          >
            <>
              <div id="bottom_divider">
                <div id="bottom_divider_inner"></div>
              </div>
              <div className="text-center">
                <div className="logo_container">
                  <img src="/static/images/logo/logo.svg" alt="Page logo" />
                </div>
                <h1 className="mb0">Stupid Media</h1>
                <h3>We fuck up, so you dont have to</h3>
              </div>
            </>
          </HeroSection>

          {window.me && (
            <HeroSection vheight={35} backgroundColor="#fff">
              <>
                <div className="text-center">
                  <h1>Vefsíður</h1>

                  <Space>
                    {this.state.websites &&
                      this.state.websites.map((website, key) => {
                        return (
                          <Card
                            title={website.name + ' (' + website.id + ')'}
                            key={key}
                          >
                            <div>Vörur: {website.product_count}</div>
                            <div>
                              <strong>
                                Alls sala: {kr(website.total_sales)}
                              </strong>
                            </div>
                            <a href={`/shop/?id=${website.id}`}>Nánar</a>
                          </Card>
                        );
                      })}
                  </Space>
                </div>
              </>
            </HeroSection>
          )}
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, { getUser })(Frontpage);
