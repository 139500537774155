export const findEmail = (string) => {
  const emailReg = new RegExp(
    /[a-zA-Z0-9]+(?:(\.|_)[A-Za-z0-9!#$%&'*+\/=?^`{|}~-]+)*@(?!([a-zA-Z0-9]*\.[a-zA-Z0-9]*\.[a-zA-Z0-9]*\.))(?:[A-Za-z0-9](?:[a-zA-Z0-9-]*[A-Za-z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/
  );
  const email = string.match(emailReg) || ['unknown'];

  return email[0];
};

export const getUrlParameter = (sParam) => {
  let sPageURL = window.location.search.substring(1);
  let sURLVariables = sPageURL.split('&');
  let sParameterName;
  let i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }

  return '';
};

export const getUrlParameters = () => {
  var keyPairs = [];
  var params = window.location.search.substring(1).split('&');
  for (var i = params.length - 1; i >= 0; i--) {
    var keyValArray = params[i].split('=');
    keyPairs.push(keyValArray[0]);
    keyPairs.push(keyValArray[1]);
  }
  return keyPairs;
};

export const kr = (x) => {
  const round = Math.round(x);
  let parts = round.toString().split('.');
  parts[0] = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  return parts.join('.') + ' kr';
};
