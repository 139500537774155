import React, { Component } from "react";
import { Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

export default class UploadImage extends Component {
    state = {
        loading: false,
        imageUrl: null,
    };

    handleChange(change) {
        let fileList = change.fileList.slice(-1);

        fileList.map((file) => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.image;
            }
            return file;
        });

        this.setState({ fileList });

        if (change.file.status === "done") {
            this.setState({ imageUrl: change.file.response.image });

            this.props.onSuccess(change.file.response.id);
        }

        if (change.file.status === "error") {
            console.log("error");
        }
    }

    transform(file) {
        return { image: file };
    }

    render() {
        return (
            <Upload
                name="image"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={true}
                fileList={
                    this.state.fileList || this.props.defaultFileList || []
                }
                action="/api/image/create/"
                headers={{
                    "x-csrftoken": document.getElementsByName(
                        "csrfmiddlewaretoken"
                    )[0].value,
                }}
                onChange={this.handleChange.bind(this)}
                multiple={false}
            >
                <div>
                    {this.state.loading ? (
                        <LoadingOutlined />
                    ) : (
                        <PlusOutlined />
                    )}
                    <div className="ant-upload-text">
                        {this.state.imageUrl
                            ? "Uppfæra mynd"
                            : "Hlaða inn mynd"}
                    </div>
                </div>
            </Upload>
        );
    }
}
