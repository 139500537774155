// CSS
import './App.less';
//BASIC REACT STUFF
import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//LIBRARIES
import { Layout } from 'antd';
import axios from 'axios';

// COMPONENTS
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

// REDUX
import store from './store';
import { Provider } from 'react-redux';

// PAGES
import Frontpage from './Pages/Frontpage/Frontpage';
import About from './Pages/About/About';
import Profile from './Pages/Profile/Profile';
import Shop from './Pages/Shop/Shop';
import Product from './Pages/Product/Product';

//LIBRARIES SETTINGS
axios.defaults.headers.common['x-csrftoken'] = document.getElementsByName(
  'csrfmiddlewaretoken'
)[0].value;

export default class App extends Component {
  render() {
    return (
      <>
        <Provider store={store}>
          <Router>
            <Layout>
              <Header></Header>
              <Layout id="mainContainer">
                <Layout>
                  <>
                    <Switch>
                      <Route path="/" exact>
                        <Frontpage></Frontpage>
                      </Route>

                      <Route path="/umokkur">
                        <About></About>
                      </Route>

                      <Route path="/profill">
                        <Profile></Profile>
                      </Route>

                      <Route path="/shop">
                        <Shop></Shop>
                      </Route>

                      <Route path="/product">
                        <Product></Product>
                      </Route>
                    </Switch>
                  </>
                </Layout>
              </Layout>

              <Footer>
                <strong>Fótur.is</strong>- Hannað og forritað af{' '}
                <a
                  href="https://pleysson.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ width: 50 }}
                    src="https://pleysson.com/static/images/logo/big.png"
                    alt="Pleysson logo"
                  />
                </a>
              </Footer>
            </Layout>
          </Router>
        </Provider>
      </>
    );
  }
}
