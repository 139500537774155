import React, { useState, useEffect } from 'react';
import { getUrlParameter, kr } from '../../utils/utils';
import { Button, AutoComplete, Space } from 'antd';
import { Table } from 'antd';
import moment from 'moment';

// Less
import './Shop.less';

const website_id = getUrlParameter('id');

const get_website = async () => {
  const res = await fetch(`/api/website/${website_id}`);
  const data = await res.json();
  return data;
};

const get_products = async () => {
  const res = await fetch(
    `/api/product/?page_size=20&ordering=-sold&website=${website_id}`
  );
  const data = await res.json();
  return data;
};

const get_snapshots = async () => {
  const res = await fetch(
    `/api/product_snapshot/?product__website=${website_id}&ordering=-created`
  );
  const data = await res.json();
  return data;
};

const search_products = async (term) => {
  const res = await fetch(
    `/api/product/?page_size=7?website=${website_id}&search=${term}`
  );
  const data = await res.json();
  return data;
};

export default function Shop() {
  const [website, setWebsite] = useState({});
  const [products, setProducts] = useState([]);
  const [productCount, setProductCount] = useState(0);
  const [options, setOptions] = useState([]);
  const [snapshots, setSnapshots] = useState([]);
  const [snapshotCount, setSnapshotCount] = useState(0);

  useEffect(() => {
    get_website().then((website) => setWebsite(website));
    get_products().then((res) => {
      console.log('res', res);
      setProducts(res.results);
      setProductCount(res.count);
    });
    get_snapshots().then((res) => {
      setSnapshots(res.results);
      setSnapshotCount(res.count);
    });
  }, []);

  const onSearch = (term) => {
    search_products(term).then((rsp) => {
      setOptions(
        rsp.results.map((product, key) => {
          return {
            key: key,
            value: product.title,
            label: (
              <div>
                <a
                  href={`/product/?product_id=${product.product_id}&website=${website.id}/`}
                >
                  {product.title}
                </a>
              </div>
            ),
          };
        })
      );
    });
  };

  const onSearchSelect = (item) => {
    console.log('item', item);
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Space direction="vertical">
        <h1>{website.name}</h1>
        <div>Vörufjöldi {productCount}</div>
        <AutoComplete
          size="large"
          placeholder={'Leita að vöru'}
          style={{ width: 300 }}
          onSearch={onSearch}
          options={options}
          onSelect={onSearchSelect}
        ></AutoComplete>
      </Space>

      <div className="product_container">
        {website.url &&
          products.map((product, key) => {
            const url = product.main_img_url;

            const parts = website.url.split('://');
            const clean_url = parts[parts.length - 1];

            const prefix =
              url && url[0] === '/'
                ? 'https://d5hu1uk9q8r1p.cloudfront.net/' + clean_url
                : '';

            const img_url = prefix + url;

            return (
              <div className="product_detail" key={key}>
                <div
                  className="product_image"
                  style={{ backgroundImage: `url(${img_url})` }}
                ></div>
                <div>
                  <h5>{product.title}</h5>
                  <div>
                    <Button>
                      <a
                        href={`/product/?website_id=${website.id}&product_id=${product.product_id}`}
                      >
                        Skoða
                      </a>
                    </Button>
                  </div>
                </div>
                <div>
                  <h2>
                    <strong>{product.sold}</strong>
                  </h2>
                </div>
              </div>
            );
          })}
      </div>
      <div className="newest_snapshots">
        <h3 style={{ textAlign: 'center' }}>
          Fjöldi skyndimynda: {snapshotCount}
        </h3>
        <Table
          pagination={{ pageSize: 200 }}
          columns={[
            { title: 'Vara', dataIndex: 'product', key: 'product' },
            { title: 'Dagsetning', dataIndex: 'date', key: 'date' },
            { title: 'Lagerstaða', dataIndex: 'quantity', key: 'quantity' },
            {
              title: 'Fjöldi selt síðan síðast',
              dataIndex: 'sold_since_last',
              key: 'sold_since_last',
            },
            {
              title: 'Innkoma síðan síðast',
              dataIndex: 'income_since_last',
              key: 'income_since_last',
            },
            { title: 'Verð', dataIndex: 'price', key: 'price' },
            { title: 'Afsláttur', dataIndex: 'discount', key: 'discount' },
          ]}
          dataSource={snapshots.map((snapshot, key) => {
            return {
              key: key,
              date: moment(snapshot.created).format('LL'),
              product: snapshot.product.title,
              quantity: snapshot.quantity,
              sold_since_last: snapshot.sold_since_last,
              income_since_last: kr(snapshot.income_since_last),
              price: kr(snapshot.price),
              discount: snapshot.discount,
            };
          })}
        ></Table>
      </div>
    </div>
  );
}
